<template>
  <v-container>
    <v-row justify="center" style="margin-top: 50px">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-1 secTitle"
            style="font-size: 40px; width: fit-content"
          >
            {{ title[lang] }}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row
      style="margin-top: 5px; margin-bottom: 50px height:fit-content "
      justify="center"
    >
      <v-col
        cols="12"
        sm="9"
        style="border: 5px solid #005677; border-radius: 10px; height: 95vh"
      >
        <v-sheet height="80vh">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            style="border: 3px solid #005677; border-radius: 10px"
            :type="type"
            @click:event="showEvent"
            @change="updateRange"
            :event-name="'name[' + lang + ']'"
            height="70vh"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title>{{
                  selectedEvent.name[lang]
                }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <img
                :src="origin + selectedEvent.photos"
                alt=""
                style="width: 350px"
              />
              <v-card-text>
                <span> {{ selectedEvent.description[lang] }}</span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  inject: ["calenderRepo"],
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {
      name: { eng: "fsafsa" },
      description: { eng: "fsafsa" },
      start: "2021-11-23 05:04:00",
      end: "2021-11-23 05:04:00",
    },
    selectedElement: null,
    selectedOpen: false,
    events: [
      {
        name: { eng: "fsafsa" },
        description: { eng: "fsafsa" },
        start: "1999-11-23 05:04:00",
        end: "1999-11-23 05:04:00",
      },
    ],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    currentMonth: 0,
    year: 0,
    temp: [],
  }),
  mounted() {
    const D = new Date();
    this.currentMonth = D.getMonth();
    this.currentMonth = this.currentMonth + 1;
    this.year = D.getFullYear();
    this.calenderRepo.all().then((response) => {
      this.events = response["data"];
      this.$store.commit("setoverlay", { overlay: false });
    });
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$store.commit("setoverlay", { overlay: true });
      this.$refs.calendar.prev();
      if (this.currentMonth == 1) {
        this.currentMonth = 12;
        this.year = this.year - 1;
      } else {
        this.currentMonth = this.currentMonth - 1;
      }
      this.calenderRepo.get(this.currentMonth, this.year).then((response) => {
        this.events = response["data"];
        console.log(response["data"]);
        this.$store.commit("setoverlay", { overlay: false });
      });
    },
    next() {
      this.$store.commit("setoverlay", { overlay: true });
      this.$refs.calendar.next();
      if (this.currentMonth == 12) {
        this.currentMonth = 1;
        this.year = this.year + 1;
      } else {
        this.currentMonth = this.currentMonth + 1;
      }

      this.calenderRepo.get(this.currentMonth, this.year).then((response) => {
        this.events = response["data"];
        this.$store.commit("setoverlay", { overlay: false });
      });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {},
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  computed: {
    origin() {
      return this.$store.state.origin;
    },
    title() {
      return this.$store.state.calenderTitle;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>
